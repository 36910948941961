import { ComponentType } from "react";
import { NavigationStyle } from "../protected-route";
import * as page from "./pages";

type CommonRouteConfiguration = {
  path: string;
  isExact?: boolean;
  page: ComponentType<any>;
  isProtected?: boolean;
  isSubRoute?: never;
  navigationStyle: NavigationStyle;
  routeGroup?: RouteGroup;
};

type RegularRouteConfiguration = CommonRouteConfiguration & {
  isProtected?: undefined;
};

type ProtectedRouteConfiguration = CommonRouteConfiguration & {
  isProtected: true;
};

type SubRoute = {
  path: string;
  isExact?: boolean;
  isSubRoute: true;
  routeGroup?: RouteGroup;
};

export type RouteConfiguration = RegularRouteConfiguration | ProtectedRouteConfiguration | SubRoute;

export const isMainRoute = (r: RouteConfiguration): r is ProtectedRouteConfiguration | RegularRouteConfiguration =>
  !r.isSubRoute;

export type RouteTreeNode = RouteConfiguration & {
  children?: RouteTreeNode[];
  parent?: RouteTreeNode;
};

const withParentLinks = (node: RouteTreeNode): RouteTreeNode => {
  return {
    ...node,
    children: node.children?.map(n => ({ ...withParentLinks(n), parent: node }))
  };
};

const isProtected = true;
const isExact = true;

const params = {
  entity: ":entity(rail|road)",
  customerid: ":customerid",
  orderid: ":orderid",
  reportLabel: ":reportLabel",
  wagonid: ":wagonid",
  stationUicCode: ":stationUicCode",
  trackId: ":trackId",
  userGroupId: ":userGroupId",
  subUserGroupId: ":subUserGroupId",
  userId: ":userId",
  trainId: ":trainId",
  dossierNumber: ":dossierNumber"
} as const;

type RouteGroup = "SEARCH" | "ORDER" | "TRAIN" | "TRACK" | "INVENTORY_AND_LOADING_INSTRUCTIONS";

export const routesByName = {
  login: { path: "/login", page: page.AsyncLoginPage, navigationStyle: "MAIN" },
  loginAski: { path: "/asiakirjat", page: page.AsyncLoginAskiPage, navigationStyle: "MAIN" },
  logoutAski: { path: "/asiakirjat/logout", page: page.AsyncLogoutAskiPage, navigationStyle: "MAIN" },
  federatedSignIn: {
    path: "/federated-signin",
    page: page.AsyncFederatedSignInPage,
    navigationStyle: "MAIN"
  },
  forgotPassword: {
    path: "/forgot-password",
    isExact,
    page: page.AsyncForgotPassword,
    navigationStyle: "MAIN"
  },
  confirmForgotPassword: {
    path: "/forgot-password/confirm",
    isExact,
    page: page.AsyncSetNewPassword,
    navigationStyle: "MAIN"
  },
  root: {
    path: "/",
    isExact,
    isProtected,
    page: page.RootPageRedirect,
    navigationStyle: "MAIN"
  },

  favorites: {
    path: "/favorites",
    isProtected,
    page: page.RootPageRedirect,
    navigationStyle: "MAIN"
  },
  changePassword: {
    path: "/options/change-password",
    page: page.AsyncChangePasswordPage,
    navigationStyle: "BACK_BURGER"
  },
  search: {
    path: "/search",
    isProtected,
    page: page.AsyncSearchPage,
    navigationStyle: "MAIN",
    routeGroup: "SEARCH"
  },

  stationSearch: {
    path: "/search/stations",
    routeGroup: "SEARCH",
    isSubRoute: true
  },

  wagonSearch: {
    path: "/search/wagons",
    routeGroup: "SEARCH",
    isSubRoute: true,
  },
  searchWagon: {
    path: `/search/wagons/${params.wagonid}`,
    isProtected,
    page: page.AsyncSearchWagonPage,
    navigationStyle: "BACK_BURGER"
  },

  boundWagons: {
    path: "/bound-wagons",
    isProtected,
    navigationStyle: "MAIN",
    page: page.AsyncBoundWagonsPage
  },

  customerOrders: {
    path: `/tracking/${params.entity}/${params.customerid}`,
    isExact,
    isProtected,
    page: page.RedirectFromTrackingToSearch,
    navigationStyle: "MAIN"
  },
  wagon: {
    path: `/wagons/${params.wagonid}`,
    isProtected,
    page: page.AsyncWagonPage,
    navigationStyle: "BACK_BURGER"
  },
  trackOrderTransports: {
    path: `/tracking/${params.entity}/${params.customerid}/${params.orderid}/transport`,
    isProtected,
    page: page.AsyncTransportPage,
    navigationStyle: "BACK_BURGER"
  },
  trackOrderTimeline: {
    path: `/tracking/${params.entity}/${params.customerid}/${params.orderid}/shipment`,
    routeGroup: "ORDER",
    isSubRoute: true,
    isExact
  },
  trackOrder: {
    path: `/tracking/${params.entity}/${params.customerid}/${params.orderid}`,
    isProtected,
    page: page.AsyncTrackOrderPage,
    navigationStyle: "ORDER",
    routeGroup: "ORDER"
  },
  userGroups: {
    path: "/admin/user-groups",
    isExact,
    isProtected,
    page: page.AsyncUserGroupPage,
    navigationStyle: "BACK_BURGER"
  },
  createUserGroup: {
    path: "/admin/create-user-group",
    isExact,
    isProtected,
    page: page.AsyncUserGroupAdministrationPage,
    navigationStyle: "BACK_BURGER"
  },
  modifyUserGroup: {
    path: `/admin/modify-user-group/${params.userGroupId}`,
    isExact,
    isProtected,
    page: page.AsyncUserGroupAdministrationPage,
    navigationStyle: "BACK_BURGER"
  },
  createSubUserGroup: {
    path: `/admin/user-groups/${params.userGroupId}/create-subgroup`,
    isExact,
    isProtected,
    page: page.AsyncCreateSubUserGroup,
    navigationStyle: "BACK_BURGER"
  },
  modifySubUserGroup: {
    path: `/admin/user-groups/${params.userGroupId}/subgroups/${params.subUserGroupId}`,
    isExact,
    isProtected,
    page: page.AsyncModifySubUserGroup,
    navigationStyle: "BACK_BURGER"
  },
  askiUserGroups: {
    path: "/admin/user-groups/aski",
    isExact,
    isProtected,
    page: page.AsyncAskiUserGroupPage,
    navigationStyle: "BACK_BURGER"
  },
  addUsersToGroup: {
    path: `/admin/${params.userGroupId}/add-users`,
    isExact,
    isProtected,
    page: page.AsyncAddUserPage,
    navigationStyle: "BACK_BURGER"
  },
  addUsersToAskiUserGroup: {
    path: `/admin/aski/${params.userGroupId}/add-users`,
    isExact,
    isProtected,
    page: page.AsyncAddAskiUserPage,
    navigationStyle: "BACK_BURGER"
  },
  user: {
    path: `/admin/user/${params.userId}`,
    isExact,
    isProtected,
    page: page.AsyncModifyUserPage,
    navigationStyle: "BACK_BURGER"
  },
  askiUser: {
    path: `/admin/aski-user/${params.userId}`,
    isExact,
    isProtected,
    page: page.AsyncModifyAskiUserPage,
    navigationStyle: "BACK_BURGER"
  },
  station: {
    path: `/stations/${params.stationUicCode}`,
    isProtected,
    page: page.AsyncStationView,
    navigationStyle: "BACK_BURGER"
  },
  track: {
    path: `/stations/${params.stationUicCode}/${params.trackId}`,
    isSubRoute: true,
    routeGroup: "TRACK",
    isExact
  },
  train: {
    path: `/trains/${params.trainId}`,
    routeGroup: "TRAIN",
    isProtected,
    page: page.AsyncTrainView,
    navigationStyle: "BACK_BURGER"
  },
  trainSchedule: {
    path: `/trains/${params.trainId}/schedule`,
    routeGroup: "TRAIN",
    isSubRoute: true,
    isExact
  },
  activityReport: {
    path: "/admin/reports/user-activity",
    isProtected,
    page: page.AsyncActivityReport,
    navigationStyle: "BACK_BURGER",
    isExact
  },
  powerBiReport: {
    path: "/reports",
    isProtected,
    page: page.AsyncPowerBiReport,
    navigationStyle: "BACK_BURGER",
    isExact
  },
  powerBiReportAccessManagement: {
    path: `/reports/access-management/${params.reportLabel}`,
    isProtected,
    page: page.AsyncPowerBiReportAccessManagement,
    navigationStyle: "BACK_BURGER"
  },
  orders: {
    path: "/orders",
    isProtected,
    page: page.AsyncOrders,
    navigationStyle: "MAIN"
  },
  ordersCreationRoad: {
    path: "/orders/road/create-order",
    isProtected,
    page: page.AsyncOrderCreationRoad,
    navigationStyle: "BACK_BURGER",
    isExact
  },
  ordersCreationRail: {
    path: "/orders/rail/create-order",
    isProtected,
    page: page.AsyncOrderCreationRail,
    navigationStyle: "BACK_BURGER",
    isExact
  },
  viewRailOrder: {
    path: `/orders/rail/view/${params.customerid}/${params.dossierNumber}`,
    isProtected,
    page: page.AsyncViewOrderPage,
    navigationStyle: "BACK_BURGER",
    routeGroup: "ORDER"
  },
  orderSummary: {
    path: `/orders/order-summary/${params.customerid}/${params.orderid}`,
    isProtected,
    page: page.AsyncOrderSummary,
    navigationStyle: "BACK_BURGER",
    isExact
  },
  releaseNotes: {
    path: "/options/release-notes",
    isProtected,
    page: page.AsyncReleaseNotesPage,
    navigationStyle: "BACK_BURGER"
  },
  cookiePolicy: {
    path: "/policies/cookie-policy",
    isExact,
    page: page.AsyncCookiePolicyPage,
    navigationStyle: "MAIN"
  },
  privacyPolicy: {
    path: "/policies/privacy-policy",
    isExact,
    navigationStyle: "MAIN",
    page: page.AsyncPrivacyPolicyPage
  },
  inventoryAndLoadingInstructions: {
    path: "/inventory-and-loading-instructions",
    isProtected,
    navigationStyle: "BACK_BURGER",
    page: page.AsyncInventoryAndLoadingInstructionsPage
  },
  instructions: {
    path: "/instructions",
    isProtected,
    navigationStyle: "BACK_BURGER",
    page: page.AsyncGeneralInstructionsPage
  },
  shunting: {
    path: "/shunting",
    isProtected,
    page: page.AsyncShuntingPage,
    navigationStyle: "MAIN"
  },
  shuntingEdit: {
    path: "/shunting-edit",
    isProtected,
    page: page.AsyncShuntingEditPage,
    navigationStyle: "MAIN"
  },
  shuntingSentOrders: {
    path: "/shunting-orders",
    isProtected,
    page: page.AsyncShuntingOrdersPage,
    navigationStyle: "MAIN"
  },
  shuntingEmptyWagonEdit: {
    path: "/wagon-type-shunting-edit",
    isProtected,
    page: page.AsyncEmptyWagonShuntingEditPage,
    navigationStyle: "MAIN"
  },
  continualShuntingEdit: {
    path: "/continual-shunting-edit",
    isProtected,
    page: page.AsyncContinualShuntingEditPage,
    navigationStyle: "MAIN"
  }
} as const;

// prettier-ignore
export const loginTree: RouteTreeNode = withParentLinks({
  ...routesByName.login, 
  children: [
    { ...routesByName.forgotPassword, children: [
      { ...routesByName.confirmForgotPassword }
    ]},
    { ...routesByName.federatedSignIn }
  ]
    
})

export const loginAskiTree: RouteTreeNode = withParentLinks({
  ...routesByName.loginAski,
  children: [
    { ...routesByName.forgotPassword, children: [{ ...routesByName.confirmForgotPassword }] },
    { ...routesByName.logoutAski }
  ]
});

const adminTree: RouteTreeNode = withParentLinks({
  ...routesByName.userGroups,
  children: [
    { ...routesByName.createUserGroup },
    { ...routesByName.modifyUserGroup },
    { ...routesByName.modifySubUserGroup },
    { ...routesByName.createSubUserGroup },
    { ...routesByName.addUsersToGroup },
    { ...routesByName.user },
    { ...routesByName.askiUser },
    { ...routesByName.activityReport },
    { ...routesByName.askiUserGroups },
    { ...routesByName.addUsersToAskiUserGroup }
  ]
});

// prettier-ignore
export const mainTree: RouteTreeNode = withParentLinks({
  ...routesByName.root,
  children: [
    { ...routesByName.favorites },
    { ...routesByName.changePassword },
    { ...routesByName.releaseNotes },
    { ...routesByName.customerOrders },
    { ...routesByName.orders, children: [
      {...routesByName.ordersCreationRoad },
      {...routesByName.ordersCreationRail },
      {...routesByName.viewRailOrder },
      {...routesByName.orderSummary }
    ] },
    { ...routesByName.powerBiReport, children: [
        { ...routesByName.powerBiReportAccessManagement },
      ]
    },
    { ...routesByName.search, children: [
      { ...routesByName.trackOrderTimeline, children: [
        { ...routesByName.trackOrderTransports }
      ]},

      { ...routesByName.trackOrder, children: [
        { ...routesByName.trackOrderTransports }
      ]},

      { ...routesByName.stationSearch, children: [
        { ...routesByName.station, children: [ 
          { ...routesByName.track } ] 
        }] 
      },

      { ...routesByName.searchWagon },
      { ...routesByName.train },
      { ...routesByName.trainSchedule },
      { ...routesByName.wagon },
      { ...routesByName.boundWagons },
      { ...adminTree }
    ]},
    { ...routesByName.inventoryAndLoadingInstructions },
    { ...routesByName.instructions },
    
    { ...routesByName.cookiePolicy },
    { ...routesByName.privacyPolicy },
    { ...routesByName.shunting },
    { ...routesByName.shuntingEdit },
    { ...routesByName.shuntingSentOrders },
    { ...routesByName.shuntingEmptyWagonEdit},
    { ...routesByName.continualShuntingEdit }
  ]
});
